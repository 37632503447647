import React from "react";
import Carousel from "react-elastic-carousel";

import "./Testimonial.scss";
import BlueHexagonInnerLine from "./../../resources/images/blueHexagonInnerLine.svg";
import BlueHexagon from "./../../resources/images/blueHexagon.svg";

const Testimonial = (props) => {
  return (
    <>
      <div className="testimonial-carousel">
        <div className="p-5 text-center">
          <h1 className="fw-bolder" style={{ fontFamily: "Montserrat" }}>
            What they say about us
          </h1>
        </div>
        <div className="container position-relative">
          <img
            className="blueHexagonInnerLine"
            src={BlueHexagonInnerLine}
            alt=""
          />
          <img className="blueHexagon" src={BlueHexagon} alt="" />
          <Carousel itemsToShow={1}>
            {props.testimonial.map((user, idx) => (
              <div
                className="testimonial-cards row justify-content-center align-items-center"
                key={idx}
              >
                <div className="testimonial-img col-md-4 col-xs-12">
                  <img src={user.img} alt="" />
                </div>
                <div className="testimonial-content col-md-8 col-xs-12">
                  <h1>{user.name}</h1>
                  <p className="testimonial-desig">{user.designation}</p>
                  <p>{user.content}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
