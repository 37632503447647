import React from "react";
import CarouselProject from "../../components/CarouselProject/CarouselProject";
import ContactForm from "../../components/ContactForm/ContactForm";
import HeroSection from "../../components/HeroSection/HeroSection";
import Navbar from "../../components/navbar/Navbar";
import OurService from "../../components/OurService/OurService";
import Testimonial from "../../components/Testimonial/Testimonial";
import Footer from "../../components/footer/Footer";
import Partners from "../../components/partners/Partners";
import CtaSection from "../../components/ctasection/CtaSection";
import Timeline from "../../components/Timeline/Timeline";

// Hero Section Image
import HeroSectionImage from "../../resources/images/BlockchainHome.png";

//our service images import
import DeFiDev from "./../../resources/images/DeFiDev.png";
import WalletDev from "./../../resources/images/WalletDev.png";
import CustomBlockchainDev from "./../../resources/images/CustomBlockchainDev.png";
import SmartContractDev from "./../../resources/images/SmartContractDev.png";
import NFTDev from "./../../resources/images/NFTDev.png";
import MetaverseDev from "./../../resources/images/MetaverseDev.png";

//testimonial images import
import dineshJindal from "./../../resources/images/dineshJindal.jpg";
// import shivamGarg from "./../../resources/images/shivamGarg.jpg";
import anoopSing from "./../../resources/images/anoopSing.jpeg";

//project images import
import Projimg1 from "../../resources/images/Project1BlockChain.jpg";
import Projimg2 from "../../resources/images/Project2BlockChain.jpg";
import Projimg3 from "../../resources/images/Project3BlockChain.jpg";
import Projimg4 from "../../resources/images/Project4BlockChain.jpg";
import Projimg5 from "../../resources/images/Project5BlockChain.jpg";

function Home() {
  const navbar = {
    firstNav: "Home",
    firstNavLink: "/blockchain",
    secondNav: "AI",
    secondNavLink: "/",
    thirdNav: "Blogs",
    thirdNavLink: "/blogs"
  };

  const heroSection = {
    title: "Discover, Develop And Decentralise With Blockchain",
    description:
      "Dwarf-AI helps businesses to decentralise and develop their products and services using blockchain, smart contracts and DApps technology",
    image: HeroSectionImage,
  };

  const cta = [
    {
      tagline:
        "Get in touch with us, to decentralise whatever and whenever you want",
    },
    [
      {
        k: "Blockchain developers assist you in deciding the new ways of doing business in decentralised and blockchain-based worlds.",
      },
      {
        k: "We dealt with the most renowned platforms and services for blockchain development.",
      },
      {
        k: "Experience and expertise that reflects on our blockchain-based solutions",
      },
      {
        k: "Top-Notch blockchain developers backed by reputed firms",
      },
      {
        k: "350+ Happy Clients",
      },
      {
        k: "Experience with startups and established businesses around the globe.",
      },
    ],
  ];

  const ourServices = [
    {
      image: DeFiDev,
      title: "Defi Development",
      desc: "By utilizing the credible Defi protocols we develop the complete products based on decentralisation and finances. Our authentic partners in the blockchain space make us unique and reliable in Defi development.",
    },
    {
      image: WalletDev,
      title: "Wallet Development",
      desc: "To build the multiple -currency exchange platforms through  decentralisation  with enhanced security feature , faster transactions and give  control of complete decentralised assets.",
    },
    {
      image: CustomBlockchainDev,
      title: "Custom Blockchain Development",
      desc: "Customised blockchain solutions for the businesses to make it more transparent , cost optimised and negate the risk faced during operations & strategizing.",
    },
    {
      image: SmartContractDev,
      title: "Smart Contract Development",
      desc: "Ranging out from different business models we provide tailored made smart contract solutions ,which are aligned to the business needs and  are cost-effctive.",
    },
    {
      image: NFTDev,
      title: "NFTs & NFT Marketplaces Development",
      desc: "We are the best in creating the NFTs and NFT market with an opportunity to trade on non-fungible tokens. We use the robust tokens stands that simplifies the trade and auditing experience.",
    },
    {
      image: MetaverseDev,
      title: "Metaverse Development",
      desc: "Scope out the business needs for the metaverse development and look after front-end and back-end implementation to launch and host projects related to metaverse.",
    },
  ];

  const projects = [
    {
      img: Projimg1,
      title: "Full Stack Web3 Application for Fantasy Gaming",
      tech: "Skills used: Solidity, Javascript, React",
      desc: "I have been working on creating a full stack web3 application for fantasy gaming which includes:",
      list: [
        {
          points:
            "Creation of smart contract using the solidity programming language.",
        },
        {
          points:
            "Creation of a backend to automatically interact with the smart contract to perform transactions.",
        },
        {
          points: "A front end to display the transactions being performed.",
        },
        {
          points:
            "It solved the problem of transparency in the current fantasy gaming systems and is able to provide more rewards to the end user with the use of Blockchain.",
        },
      ],
    },
    {
      img: Projimg2,
      title: "Supply Chain Management using Blockchain",
      tech: "Skills used: Solidity, Javascript, Hardhat",
      desc: "Currently, we do not have any good method of figuring out whether the product we have received is authentic or not. We have solved this problem using Blockchain by creating of smart contract that will generate an NFT for each product while it is manufactured and the user can verify the details from the given NFT to verify if it is authentic or not.",
      list: [],
    },
    {
      img: Projimg3,
      title: "Decentralised Exchange Platform",
      tech: "Skills Used: Ethereum, Uniswap v3, python, hardhat, solidity",
      desc: "A decentralised exchange (or DEX) is a peer-to-peer marketplace where transactions occur directly between decentralised currency traders. DEXs fulfil one of crypto’s core possibilities: fostering financial transactions that aren’t officiated by banks, brokers, or intermediaries. Many popular DEXs, like Uniswap and Sushiwap, run on the Ethereum blockchain",
      list: [
        {
          points:
            "Setting up smart contracts which include writing and deploying the contract.",
        },
        {
          points: "Aiding the investors with price forecasting mechanisms.",
        },
        {
          points:
            "Buiding the locking function to store interest-induced rewards.",
        },
        {
          points:
            "Ensure open source code so anyone interested can check how it works.",
        },
      ],
    },
    {
      img: Projimg4,
      title: "Peer 2 peer communication",
      tech: "Skills Used: Golang, libp2p, blockchain",
      desc: "P2P is a decentralised communication model that enables dual function from the client and server side by allowing each node to function. In this type of peer-to-peer communication. Each party in this model  possess equal capabilities to carry out the communications.",
      list: [
        {
          points:
            "To initiate decentralised communication We exclude the server and make computers connect with each other in a workgroup to share files, printers and access to the Internet.",
        },
        {
          points:
            "Recommended peer-to-peer software which creates a virtual network of computers.",
        },
        {
          points: "We focus on optimising transfer loads and data.",
        },
      ],
    },
    {
      img: Projimg5,
      title: "Custom blockchain",
      tech: "Skills Used: C#, HBBFT, Research",
      desc: "As a core blockchain company, we help businesses to implement and build applications, assets and functions. We serve all industries under the umbrella - from Fintech, SCM, and Healthcare, to Education, Real Estate, Gaming, and more.",
      list: [
        {
          points: "We work on block creation, block linking and adding.",
        },
        {
          points:
            "We ensure  custom blockchain development with permission based on a distributed ledger platform that brings security, efficiency, and quick processing of business operations",
        },
        {
          points:
            "We develop and maintain the investor's cabinet and distribution token system.",
        },
        {
          points:
            "In smart contract development we avoid using third parties while performing credible transactions.",
        },
      ],
    },
  ];

  const timeline = [
    {
      name: "Define",
      desc: "Meet system requirements, from defining user roles to technology-driven decision making, which primarily includes aspects of blockchain and decentralised mechanisms.",
    },
    {
      name: "Design",
      desc: "As a emerging blockchain development company, our four pillars of performance are model looks, user experience, features and project completion date.",
    },
    {
      name: "Deliver",
      desc: "The blockchain developers at DwarfAI put together all the detailed cost estimation and end-to-end execution plans for our clients.",
    },
    {
      name: "Develop",
      desc: "All updates during the product development cycle, which primarily includes DevOps, deployment and quality, were delivered through client-preferred project management tools.",
    },
  ];

  const testimonial = [
    {
      img: dineshJindal,
      name: "Dinesh Jindal",
      content:
        "Working with Dwarf AI has been an excellent experience. They created an advanced OCR Engine for me in a short period of time. The product they provided was very professional and optimised and is being used in production.",
    },
    {
      img: anoopSing,
      name: "Anoop Singh",
      designation: "Tech consultant, Aaj tak",
      content:
        "I needed a voice biometrics system for an application and Dwarf AI came to the rescue. They provided extremely lightweight application for voice biometrics with a great accuracy. They understood my requirements pretty well and was able to design it for me with minimum intervention. I will definitely work with them again.",
    },
  ];

  return (
    <div className="home">
      <div className="app position-relative">
        <button className="whatsAppBTN">
          <a href="https://api.whatsapp.com/send?phone=+918587836880&text=Hi,%20Dwarf-AI,%20I%20am%20looking%20to%20make%20my%20business%20smarter.%20Can%20we%20discuss%20further?">
            <i className="whatsAppIcon fa-brands fa-whatsapp"></i>
          </a>
        </button>
      </div>
      <Navbar navbar={navbar} />
      <HeroSection heroSection={heroSection} />
      <Partners />
      <CtaSection cta={cta} />
      <OurService ourServices={ourServices} />
      <CarouselProject projects={projects} />
      <Timeline
        timeline={timeline}
        callNowText="Build decentralised products with Dwarf AI's blockchain development services"
      />
      <Testimonial testimonial={testimonial} />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default Home;
