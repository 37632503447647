import React from "react";
import CarouselProject from "../../components/CarouselProject/CarouselProject";
import ContactForm from "../../components/ContactForm/ContactForm";
import HeroSection from "../../components/HeroSection/HeroSection";
import Navbar from "../../components/navbar/Navbar";
import OurService from "../../components/OurService/OurService";
import Testimonial from "../../components/Testimonial/Testimonial";
import Footer from "../../components/footer/Footer";
import Partners from "../../components/partners/Partners";
import CtaSection from "../../components/ctasection/CtaSection";
import Timeline from "../../components/Timeline/Timeline";
import WhatsAppIcon from "./../../resources/images/whatsappIcon.png";

// Hero Section Image
import HeroSectionImage from "../../resources/images/AIHome.png";

//our service images import
import AiDesign from "./../../resources/images/aiDesign.png";
import ComputerVision from "./../../resources/images/computerVision.png";
import NeuralNetwork from "./../../resources/images/neuralNetwork.png";
import MachhineLearning from "./../../resources/images/machineLearning.png";
import ExpertSystem from "./../../resources/images/expertSystem.png";
import NLP from "./../../resources/images/NLP.png";

//testimonial images import
import dineshJindal from "./../../resources/images/dineshJindal.jpg";
// import shivamGarg from "./../../resources/images/shivamGarg.jpg";
import anoopSing from "./../../resources/images/anoopSing.jpeg";

//project images import
import Projimg1 from "../../resources/images/Project1AI.jpg";
import Projimg2 from "../../resources/images/Project2AI.jpg";
import Projimg3 from "../../resources/images/Project3AI.jpg";
import Projimg4 from "../../resources/images/Project4AI.jpg";
import Projimg5 from "../../resources/images/Project5AI.jpg";
import Projimg6 from "../../resources/images/Project6AI.jpg";
import Projimg7 from "../../resources/images/Project7AI.jpg";

const AiPage = () => {
  const navbar = {
    firstNav: "Home",
    firstNavLink: "/",
    secondNav: "BlockChain",
    secondNavLink: "/blockchain",
    thirdNav: "Blogs",
    thirdNavLink: "/blogs"
  };

  const heroSection = {
    title: "Unlock The Potential Of AI By Building Future Applications",
    description:
      "Dwarf-AI helps you automate your business end-to-end with AI applications, software solutions, and machine learning algorithms",
    image: HeroSectionImage,
  };

  const cta = [
    {
      tagline: "Get in touch with us, to automate whatever and whenever",
    },
    [
      {
        k: "AI experts that assist you to decide the new ways of doing business",
      },
      {
        k: "Dealt with the most renowned platforms",
      },
      {
        k: "Experience that reflects on our solutions",
      },
      {
        k: "Top-Notch AI professionals backed by reputed firms",
      },
      {
        k: "250+ Happy Clients",
      },
      {
        k: "Experience with startups and established businesses around the globe.",
      },
    ],
  ];

  const ourServices = [
    {
      image: AiDesign,
      title: "blockchain Design",
      desc: "Personalized experiences and trust are things that our AI designers ensure through AI models similar to our best artificial intelligence chatbots, which deliver a feel like human touch.",
    },
    {
      image: ComputerVision,
      title: "Computer Vision",
      desc: "As a leading AI company, our focus is on analyzing, acquiring and interpreting video and digital images, which our AI engineers create by making a copy of the human visual system which often results in computer vision.",
    },
    {
      image: NeuralNetwork,
      title: "Neural Network",
      desc: "Neural Network works like the human brain that is interlinked through nodes. AI Developers focus on ai, deep learning techniques & neural networks to increase performance.",
    },
    {
      image: MachhineLearning,
      title: "Machine Learning",
      desc: " “Data is the new oil” and this is what our machine learning programming uses to make company operations easier and more efficient.The data received by our Artificial Intelligence and Machine Learning experts includes all relevant information.",
    },
    {
      image: ExpertSystem,
      title: "Expert Systems",
      desc: "Our data science and machine learning experts use an expert system backed by analysis tools and recommendation engines to solve key complex problems when building a product.",
    },
    {
      image: NLP,
      title: "Natural Language Processing",
      desc: "NLP allows natural human language to be processed via text or voice, which our artificial intelligence and machine learning experts recommend for emotional analysis.They help in building automation devices",
    },
  ];

  const projects = [
    {
      img: Projimg1,

      tech: "Skills used: Python, Sklearn,  Pandas, Xgboost",
      title: "Matching Same Sellers and Products across different platforms",
      heading: "Skills used: Python, Sklearn,  Pandas, Xgboost",
      sub: "Entity resolution is a critical problem as we can have data from different sources and there is no direct way to combine them. We solved this problem by creating a customized library for entity resolution that involved the following things:",
      list: [
        {
          points: "Blocking to create keys to avoid unnecessary comparisions.",
        },
        {
          points:
            "Scoring to calculate similarity of features among possible pairs.",
        },
        {
          points:
            "Classifcation to classify the pairs on basis of obtained scores.",
        },
        {
          points:
            "Active Learning to learn from vast unlabelled data at the time of inference.",
        },
      ],
    },
    {
      img: Projimg2,
      tech: "Skills used: Python, Tflite, Onnx, Pytorch",
      title: "Voice Authentication Based Biometrics System ",
      sub: "Implemented a biometrics system for an automobile company to validate the user using his/her voice.",
      desc: "Researched about different model architectures and optimized them to be run fast inference on edge devices. It involved:",
      list: [
        {
          points: "Noise cleaning and speech enhancement",
        },
        {
          points: "Classification using a DNN",
        },
        {
          points: "Quantization of the DNN",
        },
        {
          points: "Deploying on an Edge device",
        },
      ],
    },
    {
      img: Projimg3,
      tech: "Skills Used: Tensorflow, Python, Pygame, Pandas",
      title: "Traffic Jam Prediction on Highways",
      list: [
        {
          points:
            "Creation of animation for the traffic data of the highway to visualize the movement of cars.",
        },
        {
          points: "Detected and visualized the congestions on the highway.",
        },
        {
          points:
            "Predicted the congestions 10 seconds before they actually occured using an LSTM Network.",
        },
        {
          points:
            "Issued optimization instruction to prevent the traffic jams once they are predicted.",
        },
      ],
    },
    {
      img: Projimg4,
      title: "Fraud IP Detection in a Realtime System ",
      tech: "Skills Used: Python, Pandas, Dask",
      list: [
        {
          points:
            "Implemented a library to detect fraud and benign IPs based on their activity, and other network data once they visit a site.",
        },
        {
          points:
            "Optimized this library to work in real time and serve millions of requests within a second.",
        },
      ],
    },
    {
      img: Projimg5,
      title: "OCR Applications",
      tech: "Skills Used: Tensorflow, Python",
      list: [
        {
          points:
            "Created a library to detect and classify different kinds of texts present in a document. Its main goal was to convert unstructured Image data to structured data.",
        },
        {
          points:
            "Combined computer vision tech stacks with sequence models to get higher accuracy.",
        },
      ],
    },
    {
      img: Projimg6,
      title: "Model Compression using Quantization Aware Training",
      tech: "Skills Used: Tensorflow, Python",
      list: [
        {
          points:
            "Implemented Quantization Aware Training by using low level Tensorflow API to create a QAT library for a low precision hardware that cannot accept multiple scales and zero points, due to hardware limitation. I have also written a blog on the same and have attached the link below.",
        },
        {
          points:
            "Blog: https://medium.com/towards-data-science/inside-quantization-aware-training-4f91c8837ead",
        },
      ],
    },
    {
      img: Projimg7,
      title: "Building Deep Learning Libraries",
      tech: "Skills Used: C++, Python, Tensorflow",
      list: [
        {
          points:
            "Developed deep learning kernels like Convolution, Transpose, Zero pad, etc from scratch in C++ for a specialized accelerated hardware. Also implemented backpropogation libraries to enable edge learning and a patent has also been published for the same.",
        },
        {
          points:
            "Patent Name:  System and Method for Integer Only Quantization Aware Training for edge devices",
        },
        {
          points: "Patent Number: US 17/725500",
        },
      ],
    },
  ];

  const timeline = [
    {
      name: "Define",
      desc: "Meet system requirements, from defining user roles to technology-driven decision making, which primarily includes aspects of artificial intelligence and machine learning.",
    },
    {
      name: "Design",
      desc: "As a leading AI company, our four pillars of performance are model looks, user experience, features and project completion date.",
    },
    {
      name: "Deliver",
      desc: "The experts at DwarfAI put together all the detailed cost estimation and end-to-end execution plans for our clients.",
    },
    {
      name: "Develop",
      desc: "All updates during the product development cycle, which primarily includes DevOps, deployment and quality, were delivered through client-preferred project management tools.",
    },
  ];

  const testimonial = [
    {
      img: anoopSing,
      name: "Anoop Singh",
      designation: "Tech consultant, Aaj tak",
      content:
        "I needed a voice biometrics system for an application and Dwarf AI came to the rescue. They provided extremely lightweight application for voice biometrics with a great accuracy. They understood my requirements pretty well and was able to design it for me with minimum intervention. I will definitely work with them again.",
    },
  ];

  return (
    <div className="home">
      <div className="app position-relative">
          <a className="whatsappIconLink" href="https://api.whatsapp.com/send?phone=+918587836880&text=Hi,%20Dwarf-AI,%20I%20am%20looking%20to%20make%20my%20business%20smarter.%20Can%20we%20discuss%20further?">
            <img src={WhatsAppIcon} alt="" />
          </a>
      </div>
      <Navbar navbar={navbar} />
      <HeroSection heroSection={heroSection} />
      <Partners />
      <CtaSection cta={cta} />
      <OurService ourServices={ourServices} />
      <CarouselProject projects={projects} />
      <Timeline
        timeline={timeline}
        callNowText="Build great products with Dwarf AI's Artificial Intelligence
            Development Service"
      />
      <Testimonial testimonial={testimonial} />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default AiPage;
