import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../footer/Footer";
import BlockContent from "@sanity/block-content-to-react";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const BlogDetail = () => {
  const navbar = {
    firstNav: "Home",
    firstNavLink: "/",
    secondNav: "BlockChain",
    secondNavLink: "/blockchain",
    thirdNav: "Blogs",
    thirdNavLink: "/blogs",
  };
  const [filteredBlogs, setFilteredBlogs] = useState({
    title: "hello",
    content: "my ma,e asd lajm",
    image: "image-648a9bbeead293a7704a705abcd5202553df953f-360x205-jpg",
    // imageUrl: "https://cdn.sanity.io/images/xglwxofn/production/756c4302732a02f1705a25416f0e69bee9ff4cc7-473x250.png",
  });

  const { slug } = useParams();
  useEffect(() => {
    sanityClient
      .fetch(` *[_type == "blog" && slug.current == '${slug}'][0]`)
      .then((data) => setFilteredBlogs(data))
      .catch(console.error);
  }, [slug]);

  return (
    <>
      <Navbar navbar={navbar} />

      <div className="container blog-detail py-4 max-w-3xl mx-auto px-md-5 px-xs-2">
        <h1 className="leading-snug text-red mt-5  pb-5">
          {filteredBlogs.title}
        </h1>
        <img
          alt={filteredBlogs.title}
          src={urlFor(filteredBlogs.image).url()}
          className="responsive-img mx-auto"
        />

        <p class="card-text blog-date">
          Published on : {filteredBlogs.PublishedDate}
        </p>

        <p className="leading-snug ">{filteredBlogs.content}</p>
        <div className="flex">
          <div>
            <p className="pb-3">{filteredBlogs.author}</p>
            <BlockContent
              blocks={filteredBlogs.richtext}
              serializers={serializers}
            />

            {/* <p className="text-credmarkpurple text-base float-left">{moment(filteredBlogs.date).format('YYYY/MM/DD')}</p> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
