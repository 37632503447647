import React from "react";
import "./Timeline.scss";

function Timeline(props) {
  return (
    <>
      <div className="timeline">
        <div data-cid="service-centric-6">
          <div className="_3fZLMPLS _2dbxKckM _1BxnkSO_">
            <section className="_1YibmBYc _11ZqUWmz _2_32njdU ">
              <h2 data-testid="feature-stepper-title" className="_35agMcd-   ">
                Our Process
              </h2>
              <div className="_2qGKqRqZ">
                <div className="_1epx56Ug">
                  <div
                    className="TTvbhb5G xK1Y6wFp nSXNjloy _2hTqIJvN bMWr3Lne VRzpBOaS  "
                    role="list"
                  >
                    {props.timeline.map((item, index) => (
                      <>
                        <div
                          className="_1P9Tvb24 RqckegCL _1HQVvDUb _3jgwFSUz  "
                          role="listitem"
                        >
                          <div className="_2pfxVgGF">
                            <div className="_2ecxW5_b">{index + 1}</div>
                            <div className="p0URF4Wy">
                              <svg viewBox="0 0 15 29" className="_3c1caa7v">
                                <path
                                  d="m.707.707 13.678 13.678-13.678 13.677"
                                  fill="none"
                                  stroke="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div className="_1TA7PM5e">
                              <h3
                                className="_2yVHU0Wx"
                                data-testid="step-title"
                              >
                                {item.name}
                              </h3>
                              <div className="_12ydEHM0">{item.desc}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="row services justify-content-center align-items-center no-gutters">
        <div className="col-10 col-md-8 text-center ">
          <h4>
            {props.callNowText}
          </h4>
        </div>
        <div className=" col-10 col-md-2 text-center ">
          <a href="tel:+918587836880">
            <button className="enquireBTN">Call Now</button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Timeline;
