import React from "react";
import "./CtaSection.scss";
import bg3 from "../../resources/images/CtaImage.jpg";

const CtaSection = (props) => {

  return (
    <>
      <div className="cta-section">
        <div className="container-fluid">
          <div className="row justify-content-center h-100 align-items-center no-gutters">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="cta-content" style={{ color: "#00213a" }}>
                <h3>{props.cta[0].tagline}</h3>
                <ul>
                  {props.cta[1].map((list, index) => {
                    return <li key={index}>{list.k}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <img src={bg3} width="100%" height="100%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CtaSection;
