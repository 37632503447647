import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContactForm.scss";
import { useTimer } from "react-timer-hook";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

function MyTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "50px", color: "white" }}>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>
  );
}

const ContactForm = () => {
  const [data, setdata] = useState({
    to_name: "Kunal Goyal",
    from_name: "",
    reply_to: "",
    phone: "",
    message: "",
  });
  const [isVerified, setIsVerified] = useState(false);

  const onChangeCaptcha = (value) => {
    setIsVerified(true);
  };

  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  const OnSubmit = (e) => {
    e.preventDefault();
    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_QUERY_FORM_TEMPLATE_ID,
      data,
      process.env.REACT_APP_USER_ID
    )
      .then((response) => {
        navigate("/thankyou", { replace: true });
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const time = new Date();
  time.setSeconds(time.getSeconds() + 2500);
  return (
    <div className="contact-form-section position-relative" id="contactForm">
      <div className="container">
        <div className="row justify-content-center align-items-center no-gutters p-1">
          <div className="col-lg-6 col-12">
            <div className="formBox">
              <p className="expiry-form">Offer Expires in</p>
              <MyTimer expiryTimestamp={time} />
              <p className="offerText">Get instant 50% off on our services</p>
              <p>Automate your business 3x</p>
              <form onSubmit={OnSubmit} autoComplete="off">
                <input
                  type="text"
                  name="from_name"
                  placeholder="Your Name"
                  value={data.from_name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="reply_to"
                  placeholder="Email"
                  value={data.reply_to}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone"
                  value={data.phone}
                  onChange={handleChange}
                  required
                />
                <textarea
                  name="message"
                  cols="50"
                  rows="3"
                  placeholder="Describe your query..."
                  value={data.message}
                  onChange={handleChange}
                  required
                />
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChangeCaptcha}
                />
                <button
                  disabled={!isVerified}
                  className="formSubmit"
                  type="submit"
                >
                  Contact Us
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
