import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ThankyouPage from "./pages/home/ThankyouPage";
import AiPage from "./pages/home/AiPage";
import BlockchainPage from "./pages/home/BlockchainPage";
import Blogs from "./pages/blogs/Blogs";
import BlogDetail from "./components/BlogsComponent/BlogDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AiPage />} />
        <Route path="/blockchain" element={<BlockchainPage />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
