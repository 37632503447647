import React from "react";
import Carousel from "react-elastic-carousel";
import "./CarouselProject.scss";
import Item from "./Item.js";

const CarouselProject = (props) => {
  const itemsToShow = 3;
  // const getMid = () => Math.ceil(itemsToShow / 2) - 1; // 0 based
  // const [midItemIndex, setMidItemIndex] = useState(getMid);
  // const onChange = (_, next) => {
  //   const mid = getMid();
  //   setMidItemIndex(mid + next.index);
  // };
  return (
    <>
      <div className="project-carousel" id="projects">
        <div className="pb-5 text-center">
          <h1
            className="fw-bolder"
            style={{
              fontWeight: "bolder",
              color: "#00213a",
              fontFamily: "Montserrat",
            }}
          >
            Our Projects
          </h1>
        </div>

        <div className="container-fluid">
          <Carousel
            itemsToShow={itemsToShow}
            // onNextStart={onChange}
            // onPrevStart={onChange}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 550, itemsToShow: 2 },
              { width: 850, itemsToShow: 3 },
              { width: 1750, itemsToShow: 3 },
            ]}
          >
            {props.projects.map((item, idx) => (
              <Item items={item} key={idx} />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default CarouselProject;
