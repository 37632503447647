import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BlogsComponent from "../../components/BlogsComponent/BlogsComponent";
import sanityClient from "../../client";
import Navbar from "../../components/navbar/Navbar";
import "../../components/BlogsComponent/Blogs.scss";
import Footer from "../../components/footer/Footer";
// import Navbar from "../../components/navbar/Navbar";

const Blogs = () => {
  const navbar = {
    firstNav: "Home",
    firstNavLink: "/",
    secondNav: "BlockChain",
    secondNavLink: "/blockchain",
    thirdNav: "Blogs",
    thirdNavLink: "/blogs",
  };

  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "blog"]`
        // {
        // title,
        // content,
        // slug,
        // image{
        //     asset->{
        //     _id,
        //     url
        //     },
        // },
        // }
      )
      .then((data) => setBlogs(data))
      .catch(console.error);
  }, []);
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <Navbar navbar={navbar} />
      <div className="our-blogs position-relative" id="our-service">
        <h1 className="fw-bolder">Our Blogs</h1>
        <hr style={{ width: "10px", color: "red" }}></hr>
        <br></br>
        <div className="container p-4">
          <div className="row d-flex align-items-stretch justify-content-center container">
            {blogs &&
              blogs.map((blog, idx) => (
                <div
                  key={idx}
                  className="col-lg-4 col-md-6 col-12 text-center p-4"
                >
                  <BlogsComponent blog={blog} key={idx} />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* {
        blogs && blogs.map((blog,idx) => (
                <>
                    <Category key={category._id} blog={blog} />
                    <BlogsComponent blog={blog} key={idx} />
                </>
            ))
    } */}
      <Footer />
    </>
  );
};

export default Blogs;
