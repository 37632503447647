import React from "react";
import "./ThanksSection.scss";

const ThanksSection = () => {
  return (
    <>
      <div className="thanks-section">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1>Thank you !</h1>
            <p>Thank you for connecting with team Dwarf AI.</p>
            <p>
              Our team will get in touch with you shortly. You can Connect with
              our Project Manager Instantly.
            </p>
            <div className="buttonContainer">
              <a href="tel:+918700964103">
                <button className="enquireBTN">Call Now</button>
              </a>
              <button className="whatsappBTN">
                <a href="https://api.whatsapp.com/send?phone=+918587836880&text=Hi,%20Dwarf-AI,%20I%20am%20looking%20to%20make%20my%20business%20smarter.%20Can%20we%20discuss%20further?">
                  <i className="whatsappIcon fa-brands fa-whatsapp"></i>
                  Connect on WhatsApp
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ThanksSection;
