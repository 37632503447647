import React from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const pathname = window.location.pathname;
console.log(pathname);

const BlogsComponent = ({ blog }) => {
  return (
    <>
      <div class="card blog-card h-100 mx-auto p-2 m-3">
        <Link to={"/blogs/" + blog.slug.current}>
          <img
            height={"180px"}
            alt={blog.title}
            src={urlFor(blog.image).url()}
            className="card-img-top"
          />
        </Link>
        <div class="card-body text-left">
          <Link
            to={"/blogs/" + blog.slug.current}
            style={{ textDecoration: "none" }}
          >
            <h5 className="card-title text-black  blog-title">{blog.title}</h5>
          </Link>
          <p class="card-text blog-content pt-3">{blog.content}</p>
          <p class="card-text blog-date">{blog.PublishedDate}</p>
        </div>
      </div>
    </>
  );
};

export default BlogsComponent;
