import React from "react";
import "./Partners.scss";
import GojekLogo from "./../../resources/images/gojekLogo.svg";
import GrabLogo from "./../../resources/images/grabLogo.svg";
import DanaLogo from "./../../resources/images/danaLogo.svg";
import ShopeePayLogo from "./../../resources/images/shopeePayLogo.svg";
import ZomatoLogo from "./../../resources/images/zomatoLogo.svg";

function Partners() {
  return (
    <div className="partners">
      <div
        style={{
          color: "#BBB",
          fontFamily: "Montserrat",
          fontWeight: "bold",
        }}
        className="text-center p-4 "
      >
        <h5>- OUR CLIENTS - </h5>
      </div>
      <div className="container">
        <div className="marquee">
          <div className="marquee-content">
            <div className="marquee-item">
              <img src={GojekLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={GrabLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={DanaLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={ShopeePayLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={ZomatoLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={GojekLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={GrabLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={DanaLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={ShopeePayLogo} alt="" />
            </div>

            <div className="marquee-item">
              <img src={ZomatoLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
