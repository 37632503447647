import React from "react";
import "./OurService.scss";

const OurService = (props) => {
  return (
    <>
      <div className="our-service position-relative" id="our-service">
        <h1 className="fw-bolder">Our services for you</h1>
        <div className="container p-4">
          <div className="row d-flex align-items-stretch justify-content-center">
            {props.ourServices.map((service, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-12 text-center  p-4"
              >
                <div className="service-card">
                  <img className="" src={service.image} alt="" height="70px" />
                  <h5>{service.title}</h5>
                  <p>{service.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
