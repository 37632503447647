import React from "react";
import Navbar from "../../components/navbar/Navbar";
import ThanksSection from "../../components/ThankYou/ThanksSection";

const ThankyouPage = () => {
  const navbar = {
    firstNav: "Home",
    firstNavLink: "/",
    secondNav: "AI",
    secondNavLink: "/",
    thirdNav: "Blogs",
    thirdNavLink: "/blogs",
  };
  return (
    <>
      <Navbar navbar={navbar} />
      <ThanksSection />
    </>
  );
};

export default ThankyouPage;
