import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Item = ({ items, idx }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    width: "100%",
    backgroundColor: "#683bb7",
    position: "relative",
    color: "#fff",
    fontSize: "4em",
  };

  return (
    <div className="carousel-content" style={styles} onClick={toggleShow}>
      <img
        src={items.img}
        alt=""
        style={{
          height: "100%",
          width: " 100%",
          borderRadius: "10px",
        }}
      ></img>
      <div>
        <div className="carousel-title">
          <h4>{items.title}</h4>
        </div>
      </div>

      <Modal show={show} size="xl " onHide={toggleShow}>
        <Modal.Body className="p-4">
          <Modal.Header closeButton></Modal.Header>
          <div className="text-center p-4">
          </div>
          <div className="row align-items-center justify-content-center h-100 ">
            <div className="col-md-5" style={{ fontWeight: "100" }}>
              <h5
                className="pb-1"
                style={{
                  fontFamily: `"Montserrat", sans-serif`,
                  textTransform: "uppercase",
                }}
              >
                {items.title}
              </h5>
              <p>{items.tech}</p>
              <p>{items.sub}</p>
              <p>{items.desc}</p>
              {items.list.map((listt, index) => {
                return (
                  <li key={index} style={{ color: "black" }}>
                    {listt.points}
                  </li>
                );
              })}
            </div>
            <div className="col-md-5 text-center">
              <div className="p-4" style={{ background: "#FFF1C3" }}>
                <h3 className="p-2">{items.title}</h3>
                <img
                  src={items.img}
                  alt=""
                  style={{
                    height: "90%",
                    width: "90%",
                    borderRadius: "10px",
                  }}
                ></img>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleShow}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Item;
